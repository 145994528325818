import React, { createContext, useContext, useState, useEffect } from "react";
import firebase from "./firebase";

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const registerUser = (user) => {
        return firebase.firestore().collection("users").doc(user.uid).set({
            displayName: user.displayName,
            email: user.email,
        });
    };

    const handleSignInOrRegister = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase
            .auth()
            .signInWithPopup(provider)
            .then((result) => {
                const user = result.user;
                firebase
                    .firestore()
                    .collection("users")
                    .doc(user.uid)
                    .get()
                    .then((doc) => {
                        if (!doc.exists) {
                            registerUser(user);
                        }
                    })
                    .catch((error) => {
                        console.error("Error checking user registration: ", error);
                    });
            })
            .catch((error) => {
                console.error("Error during Google sign-in: ", error);
            });
    };

    const signOut = () => {
        return firebase
            .auth()
            .signOut()
            .then(() => {
                localStorage.removeItem("user");
            })
            .catch((error) => {
                console.error("Error during sign-out: ", error);
            });
    };

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            setCurrentUser(user);
            setLoading(false);

            if (user) {
                localStorage.setItem("user", JSON.stringify(user));
            } else {
                localStorage.removeItem("user");
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
            setCurrentUser(JSON.parse(storedUser));
        }
        setLoading(false);
    }, []);

    const value = {
        currentUser,
        handleSignInOrRegister,
        signOut,
    };

    return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};