// ContinueButton.js
import React from 'react';
import { Button, CircularProgress } from "@mui/material";
import { FormattedMessage } from 'react-intl';

const ContinueButton = ({ disabled, onClick, loading }) => {
    return (
        <Button
            variant="contained"
            color="primary"
            disabled={disabled || loading}
            onClick={onClick}
            style={{ textTransform: 'none' }}
        >
            {loading ? (
                <CircularProgress size={24} />
            ) : (
                <FormattedMessage
                    id="search.checkout"
                    defaultMessage="Continue"
                />
            )}
        </Button>
    );
};

export default ContinueButton;