import en from './en.json';
import ko from './ko.json';
import ja from './ja.json';
import id from './id.json';
import es from './es.json';
import fr from './fr.json';
import de from './de.json';
import mn from './mn.json';
import zh from './zh.json';

const translations = {
    en,
    ko,
    ja,
    id,
    es,
    fr,
    de,
    mn,
    zh,
};

export default translations;