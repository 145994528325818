// src/routes/index.js
import React from 'react';
import LandingPage from "../pages/LandingPage";
import Search from "../pages/Search";
import SearchHistory from "../pages/SearchHistory";
import FreemiumSearch from "../pages/FreemiumSearch";

export const routes = [
    {
        id: 1,
        name: "Landing Page",
        path: "/",
        element: LandingPage,
    },
    {
        id: 2,
        name: "Search",
        path: "/search",
        element: (props) => <Search {...props} selectedLanguage={props.selectedLanguage} setSelectedLanguage={props.setSelectedLanguage} />,
    },
    {
        id: 2,
        name: "Try Search",
        path: "/try-search",
        element: (props) => <FreemiumSearch {...props} selectedLanguage={props.selectedLanguage} setSelectedLanguage={props.setSelectedLanguage} />,
    },
    {
        id: 3,
        name: "Search History",
        path: "/search-history",
        element: (props) => <SearchHistory {...props} selectedLanguage={props.selectedLanguage} setSelectedLanguage={props.setSelectedLanguage} />,
    }
];