// ImageUploader.js
import React, { useRef } from 'react';
import { IconButton } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormattedMessage } from 'react-intl';
import styled from "styled-components";

const PreviewContainer = styled.div`
  max-width: 100%;
`;

const ImageUploader = ({ file, previewUrl, onFileUpload, onDeleteImage }) => {
    const previewRef = useRef(null);

    const deleteBtn = {
        position: "absolute",
        top: 10,
        right: 10,
    };

    const handleFileUpload = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            if (selectedFile.type.startsWith("image/")) {
                if (selectedFile.size <= 10 * 1024 * 1024) {
                    onFileUpload(selectedFile);
                } else {
                    alert("File size exceeds 10MB limit");
                }
            } else {
                alert("Please select a valid image file (png, jpg, jpeg)");
            }
        }
    };

    const adjustPreviewDimensions = () => {
        if (previewUrl) {
            const img = new Image();
            img.src = previewUrl;
            img.onload = () => {
                const maxWidth = 400;
                const maxHeight = 400;
                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                previewRef.current.style.width = `${width}px`;
                previewRef.current.style.height = `${height}px`;
            };
        }
    };

    return (
        <PreviewContainer className="search-file-upload">
            <div className="common-box-container">
                <div className="common-box">
                    {previewUrl ? (
                        <>
                            <div className="preview-image">
                                <img
                                    ref={previewRef}
                                    src={previewUrl}
                                    alt="Preview"
                                    onLoad={adjustPreviewDimensions}
                                />
                            </div>
                            <IconButton
                                color="error"
                                sx={deleteBtn}
                                aria-label="delete"
                                size="small"
                                onClick={onDeleteImage}
                            >
                                <DeleteIcon color="error" fontSize="medium" />
                            </IconButton>
                        </>
                    ) : (
                        <label htmlFor="imgUpload" className="upload-box">
                            <div className="upload-content">
                                <FileUploadOutlinedIcon fontSize="large" style={{ color: "black" }} />
                                <FormattedMessage
                                    id="search.uploadImage"
                                    defaultMessage="Upload Image"
                                />
                            </div>
                            <input
                                id="imgUpload"
                                onChange={handleFileUpload}
                                style={{ display: "none" }}
                                accept="image/jpeg,image/png"
                                type="file"
                            />
                        </label>
                    )}
                </div>
                <ul className="instructions-list">
                    <li>
                        <FormattedMessage
                            id="search.instructions.quality"
                            defaultMessage="1. High quality, high resolution that shows the face clearly"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="search.instructions.format"
                            defaultMessage="2. Supported formats: png, jpg or jpeg"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="search.instructions.size"
                            defaultMessage="3. Size must be less than 10MB"
                        />
                    </li>
                </ul>
            </div>
        </PreviewContainer>
    );
};

export default ImageUploader;