import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import ContinueButton from './ContinueButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, CircularProgress } from "@mui/material";

const PaymentOptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

const PaymentOptionCell = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(0, 119, 228, 0.1);
  }

  @media (max-width: 480px) {
    flex-direction: column;
    text-align: center;
  }
`;

const PaymentOptionImage = styled.img`
  max-width: 200px;
  width: auto;
  max-height: 200px;
  height: auto;

  @media (max-width: 480px) {
    max-width: 150px;
    max-height: 150px;
  }
`;

const CheckmarkIcon = styled(CheckCircleIcon)`
  color: #0077e4;
  margin-left: 10px;

  @media (max-width: 480px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

const PaymentOptionSelector = ({ onContinue, startPaymentProcess, loadingProgress }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [continueLoading, setContinueLoading] = useState(false);

    const handleOptionClick = option => {
        setSelectedOption(option);
    };

    const handleContinueClick = () => {
        if (selectedOption) {
            setContinueLoading(true);
            onContinue(selectedOption);
            startPaymentProcess();
        }
    };

    return (
        <>
            <h5 style={{ textAlign: 'center', marginBottom: '20px' }}>
                <FormattedMessage
                    id="payment.chooseMethod"
                    defaultMessage="Step 2. Choose your method of payment"
                />
            </h5>
            <PaymentOptionsContainer>
                <PaymentOptionCell onClick={() => handleOptionClick('stripe')}>
                    <PaymentOptionImage src="/images/stripe-logo.png" alt="Stripe" />
                    {selectedOption === 'stripe' && <CheckmarkIcon />}
                </PaymentOptionCell>
                <p></p>
                <PaymentOptionCell onClick={() => handleOptionClick('qpay')}>
                    <PaymentOptionImage src="/images/qpay-logo.png" alt="QPay" />
                    {selectedOption === 'qpay' && <CheckmarkIcon />}
                </PaymentOptionCell>
            </PaymentOptionsContainer>
            <Button
                variant="contained"
                color="primary"
                disabled={!selectedOption}
                onClick={handleContinueClick}
                style={{ textTransform: 'none' }}
            >
                {loadingProgress ? (
                    <CircularProgress size={24} />
                ) : (
                    <FormattedMessage
                        id="payment.continue"
                        defaultMessage="Continue"
                    />
                )}
            </Button>
        </>
    );
};

export default PaymentOptionSelector;