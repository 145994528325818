import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

export const BuyButtonComponent = ({ uid, docId }) => {
    const stripeKey = process.env.REACT_APP_STRIPE_PK;
    const buyButtonId = process.env.REACT_APP_STRIPE_BUY_BUTTON_ID;
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://js.stripe.com/v3/buy-button.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const clientReferenceId = `${uid}_${docId}`;

    return (
        <>
            <h5 style={{ textAlign: 'center' }}>
                <FormattedMessage
                    id="payment.makePayment"
                    defaultMessage="Step 3. Make a payment"
                />
            </h5>
            {!isButtonClicked ? (
                <stripe-buy-button
                    buy-button-id={buyButtonId}
                    publishable-key={stripeKey}
                    client-reference-id={clientReferenceId}
                >
                </stripe-buy-button>

            ) : (
                <div>
                    <FormattedMessage
                        id="loading"
                        defaultMessage="Loading..."
                    />
                </div>
            )}
        </>
    );
};