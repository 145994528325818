// src/App.js
import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import { routes } from "./routes";
import { IntlProvider } from 'react-intl';
import "./App.css";
import translations from './translations';


const App = () => {
    const [selectedLanguage, setSelectedLanguage] = useState('en');
    return (
        <IntlProvider locale={selectedLanguage} messages={translations[selectedLanguage]} >
            <Router>
                <AuthProvider>
                    <Routes>
                        {routes.map((r) => {
                            const Component = r.element;
                            return (
                                <Route
                                    key={r.id}
                                    path={r.path}
                                    element={<Component selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />}
                                />
                            );
                        })}
                    </Routes>
                </AuthProvider>
            </Router>
        </IntlProvider >
    );
};

export default App;
