import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { DashboardLayout } from "../components/DashboardLayout";
import { BuyButtonComponent } from '../components/BuyButtonComponent';
import ImageUploader from '../components/ImageUploader';
import ContinueButton from '../components/ContinueButton';
import { validateFacesearchBalance } from '../components/facesearchBalanceHelper';
import { FormattedMessage } from 'react-intl';
import firebase from "../firebase";
import Lottie from 'react-lottie';
import animationData from '../../src/lotties/searching.json';
import paymentCheckData from '../../src/lotties/payment-check.json';
import { Grid, Button, CircularProgress, Dialog, DialogContent, DialogActions, Modal, Box, Accordion, AccordionSummary, AccordionDetails, Typography, ListItem, ListItemIcon, ListItemText, Collapse } from "@mui/material";
import styled from "styled-components";
import PaymentOptionSelector from '../components/PaymentSelector';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IconButton } from "@mui/material";
import ReactGA from 'react-ga4';

const SystemMessage = styled.div`
  color: #ff5733;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
`;

const BuyButtonOverlay = styled(Button)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    text-transform: none;
`;

const ShowQRButton = styled(Button)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const Search = ({ selectedLanguage, setSelectedLanguage }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [remainingTime, setRemainingTime] = useState(null);
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [continueClicked, setContinueClicked] = useState(false);
    const [balanceValid, setBalanceValid] = useState(false);
    const [systemUpdating, setSystemUpdating] = useState(false);
    const [searching, setSearching] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const { currentUser, loading } = useAuth();
    const [continueLoading, setContinueLoading] = useState(false);
    const [searchDocRef, setSearchDocRef] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const [paymentError, setPaymentError] = useState(false);
    const [searchCompleted, setSearchCompleted] = useState(false);
    const [searchError, setSearchError] = useState(false);
    const [showQRCodeDialog, setShowQRCodeDialog] = useState(false);
    const [QRCodeImage, setQRCodeImage] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [qPayError, setQPayError] = useState(null);
    const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
    const [showQRButton, setShowQRButton] = useState(false);
    const [showBankList, setShowBankList] = useState(false);
    const [qPayMobileBankData, setQPayMobileBankData] = useState(null);
    const [qrCodeData, setQRCodeData] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState('');
    const [loadingProgress, setLoadingProgress] = useState(false);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const checkIntervalRef = useRef(null);
    const [qPayLoading, setQPayLoading] = useState(false);
    const [expandedDomains, setExpandedDomains] = useState([]);
    const [groupedData, setGroupedData] = useState(null);
    const [sortedDomains, setSortedDomains] = useState(null);
    const [topDomains, setTopDomains] = useState(null);
    const [sortedImages, setSortedImages] = useState(null);

    useEffect(() => {
        ReactGA.initialize('G-0EB4JL6F6T');
    }, []);

    useEffect(() => {
        // Check if the screen width is less than or equal to 600px (mobile view)
        const handleResize = () => {
            const isMobileView = window.innerWidth <= 600;
            setIsMobile(isMobileView);
        };

        // Set initial state
        handleResize();

        // Add event listener for screen resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleNewSearch = () => {
        setFile(null);
        setPreviewUrl(null);
        setContinueClicked(false);
        setBalanceValid(false);
        setPaymentError(false);
        setSearching(false);
        setApiResponse(null);
        setGroupedData(null);
        setSortedDomains(null);
        setTopDomains(null);
        setSortedImages(null);
        setPaymentSuccess(false);
        setRemainingTime(null);
        setSearchError(null);
        setSearchCompleted(false);
        setShowQRCodeDialog(false);
        setQRCodeImage(null);
        setQPayError(null); // Reset the qPayError state
        setSelectedOption(null);
        setIsPaymentProcessing(false);
    };

    if (loading) {
        return <div>
            <FormattedMessage
                id="loading"
                defaultMessage="Loading..."
            />
        </div>;
    }

    if (!currentUser) {
        navigate("/");
        return null;
    }


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const paymentCheckOptions = {
        loop: true,
        autoplay: true,
        animationData: paymentCheckData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const handleFileUpload = (selectedFile) => {
        setFile(selectedFile);

        const fileReader = new FileReader();
        fileReader.onload = () => {
            setPreviewUrl(fileReader.result);
        };
        fileReader.readAsDataURL(selectedFile);
    };

    const handleDeleteImage = () => {
        setFile(null);
        setPreviewUrl(null);
        setContinueClicked(false);
        setBalanceValid(false);
    };

    const handleContinueClick = async () => {
        setContinueLoading(true);
        try {
            const isBalanceValid = await validateFacesearchBalance();

            if (isBalanceValid) {
                const storageRef = firebase.storage().ref();
                const userFolderRef = storageRef.child(`users/${currentUser.uid}`);
                const timestamp = new Date().toISOString().replace(/[-:.]/g, "-");
                const timestampFolderRef = userFolderRef.child(timestamp);
                const fileRef = timestampFolderRef.child(file.name);

                await fileRef.put(file);
                const downloadURL = await fileRef.getDownloadURL();

                const searchDocId = formatDate(new Date());
                const docRef = await firebase
                    .firestore()
                    .collection(`users/${currentUser.uid}/searches`)
                    .doc(searchDocId);

                await docRef.set({
                    createdAt: searchDocId,
                    image: downloadURL,
                    result: 'pending',
                    payment: {
                        status: "pending",
                    },
                });

                setSearchDocRef(docRef);
                setContinueClicked(true);
                setBalanceValid(true);
            } else {
                setSystemUpdating(true);
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setContinueLoading(false);
        }
    };

    const handleFaceSearch = async (searchDocRef) => {
        setSearching(true);
        setSearchError(null);
        setSearchCompleted(false);

        ReactGA.event({
            category: 'purchasedSearch',
            action: 'purchasedSearch',
            label: 'purchasedSearch',
            value: 5
        });

        try {
            const searchDoc = await searchDocRef.get();
            const downloadURL = searchDoc.data().image;

            let searchFaceUrl = process.env.REACT_APP_FACE_URL;
            const response = await fetch(searchFaceUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    imageUrl: downloadURL,
                }),
            });

            if (!response.ok) {
                const errorMessage = await response.text();
                console.log(errorMessage);
                throw new Error(errorMessage);
            }

            const responseData = await response.json();
            // Save the API response to a JSON file in Firebase Storage
            const storageRef = firebase.storage().ref();
            const userFolderRef = storageRef.child(`users/${currentUser.uid}`);
            const searchFolderPath = searchDoc.data().image.split('?')[0].split('/').slice(3).join('/');
            const searchFolderRef = userFolderRef.child(searchFolderPath);
            const resultFileRef = searchFolderRef.child('result.json');
            const resultFileBlob = new Blob([JSON.stringify(responseData)], { type: 'application/json' });
            await resultFileRef.put(resultFileBlob);
            const resultFileURL = await resultFileRef.getDownloadURL();

            await searchDocRef.update({
                result: resultFileURL,
            });

            setApiResponse(responseData);

            const groupedData = responseData.reduce((acc, item) => {
                const domain = new URL(item.url).hostname;
                if (!acc[domain]) {
                    acc[domain] = [];
                }
                acc[domain].push(item);
                return acc;
            }, {});
            const sortedDomains = Object.entries(groupedData).sort((a, b) => b[1].length - a[1].length);
            const topDomains = sortedDomains.slice(0, 5);
            const sortedImages = responseData.sort((a, b) => b.score - a.score);

            setGroupedData(groupedData);
            setSortedDomains(sortedDomains);
            setTopDomains(topDomains);
            setSortedImages(sortedImages);

            setSearchCompleted(true);
        } catch (error) {
            console.error("Error:", error);
            if (error.message.includes('IMAGE_ERROR')) {
                setSearchError('It looks like there is an issue with the image uploaded. Please make sure it includes a clear face.');
            } else {
                setSearchError('An error has occurred when trying to do a search. Please wait for a bit and try again.');
            }

        } finally {
            setSearching(false);
            setFile(null);
            setPreviewUrl(null);
        }
    };

    const formatDate = (date) => {
        const pad = (num) => (num < 10 ? "0" + num : num);
        return (
            pad(date.getMonth() + 1) +
            "-" +
            pad(date.getDate()) +
            "-" +
            date.getFullYear() +
            "-" +
            pad(date.getHours()) +
            "-" +
            pad(date.getMinutes()) +
            "-" +
            pad(date.getSeconds())
        );
    };

    const getColorBasedOnScore = (score) => {
        if (score >= 90) return "#50C878";
        else if (score > 60) return "orange";
        else return "#FF5733";
    };

    const handleQPayPayment = async () => {
        try {
            setQPayLoading(true); // Set loading state to true before making the API call
            const qpayResponse = await fetch(process.env.REACT_APP_QPAY_INVOICE_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    amount: 20000,
                })
            });

            if (!qpayResponse.ok) {
                setQPayError('Failed to process QPay payment');
                throw new Error('Failed to process QPay payment');
            }
            const data = await qpayResponse.json();
            const { qr_image, invoice_id, qr_text, urls } = data.response;

            // Update the payment field in Firestore with the received data
            await searchDocRef.update({
                'payment.data.qr_image': qr_image,
                'payment.data.invoice_id': invoice_id,
                'payment.data.qr_text': qr_text,
                'payment.data.urls': urls,
            });

            const qrImage = `data:image/jpeg;base64,${qr_image}`;
            setQRCodeData({ qr_image, invoice_id });
            setQPayMobileBankData(urls);
            setLoadingProgress(false);
            setQRCodeImage(qrImage);
            setShowQRCodeDialog(true);
            setIsPaymentProcessing(true);

            startPaymentCheckingProcess();
        } catch (error) {
            console.error('QPay payment error:', error);
            setSearchError(error.message);
        } finally {
            setQPayLoading(false); // Set loading state to false after the API call is completed or an error occurs
        }
    };

    const BankListOverlay = ({ bankData, onClose }) => {
        return (
            <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '90%', maxHeight: '80vh', overflowY: 'auto', position: 'relative' }}>
                    <IconButton
                        onClick={onClose}
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            color: '#000',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                        {bankData.map((bank, index) => (
                            <div key={index} style={{ padding: '10px', textAlign: 'center', width: '33.33%', boxSizing: 'border-box' }}>
                                <a href={bank.link} target="_blank" rel="noopener noreferrer">
                                    <img src={bank.logo} alt={bank.name} style={{ width: '100%', height: 'auto', borderRadius: '15px' }} />
                                </a>
                            </div>
                        ))}
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <Button onClick={onClose} variant="contained" style={{ backgroundColor: "#3554A4" }}>
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const startPaymentCheckingProcess = async () => {
        setRemainingTime(120);
        setIsPaymentProcessing(true);
        setShowOverlay(true);

        checkIntervalRef.current = setInterval(async () => {
            const searchDoc = await searchDocRef.get();
            const paymentStatus = searchDoc.data().payment.status;

            if (paymentStatus === 'paid') {
                clearInterval(checkIntervalRef.current);
                setShowOverlay(false);
                setPaymentSuccess(true);
                setIsPaymentProcessing(false);
                await handleFaceSearch(searchDocRef);
            } else {
                setRemainingTime((prevTime) => {
                    const newTime = prevTime - 1;
                    if (newTime <= 0) {
                        clearInterval(checkIntervalRef.current);
                        setShowOverlay(false);
                        setPaymentError(true);
                        setIsPaymentProcessing(false);
                        setShowBankList(false);
                        setShowQRCodeDialog(false);
                        return 0;
                    }
                    return newTime;
                });
            }
        }, 1000);
    };

    const handlePaymentOptionSelected = async (option) => {
        setLoadingProgress(true);
        setSelectedOption(option);

        if (option === 'qpay') {
            try {
                await searchDocRef.update({
                    "payment.type": 'qpay'
                });
                await handleQPayPayment();
            } catch (error) {
                console.error('Error updating payment type:', error);
                // Still continue;
                await handleQPayPayment();
            }
        } else if (option === 'stripe') {
            try {
                await searchDocRef.update({
                    "payment.type": 'stripe'
                });
                setTimeout(() => {
                    startPaymentCheckingProcess();
                }, 10000);
            } catch (error) {
                console.error('Error updating payment type:', error);
                // Still continue;
                setTimeout(() => {
                    startPaymentCheckingProcess();
                }, 10000);
            }
        }

        setLoadingProgress(false);
    };

    const handleCheckPayment = async () => {
        try {
            setLoadingProgress(true);
            const response = await fetch(process.env.REACT_APP_QPAY_CHECK_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ invoice_id: qrCodeData.invoice_id }),
            });

            const data = await response.json();
            const { count, paid_amount, rows } = data.resp;
            if (count === 0 || (count > 0 && rows.every(row => row.payment_status === 'PAID'))) {
                await searchDocRef.update({
                    'payment.status': 'paid',
                });
                setPaymentStatus(null);
            } else {
                setPaymentStatus("Payment isn't complete, yet");
            }
        } catch (error) {
            console.error("Payment check failed:", error);
            setPaymentStatus('Error occurred while checking payment');
        } finally {
            setLoadingProgress(false);
        }
    };

    const handleCloseQRCodePage = () => {
        setShowCancelConfirmation(true);
    };

    const handleCancelPayment = async () => {
        try {
            await searchDocRef.update({
                'payment.status': 'user canceled',
                'result': 'user canceled',
            });
            setShowCancelConfirmation(false);
            setPaymentStatus(null);
            setShowQRCodeDialog(false);
            setShowOverlay(false);

            // Clear the payment checking interval
            clearInterval(checkIntervalRef.current);

            // Call handleNewSearch to reset states and show the image uploader
            handleNewSearch();
        } catch (error) {
            console.error("Error updating payment and search status:", error);
        }
    };

    const startPaymentProcess = () => {
        if (selectedOption === 'stripe') {
            startPaymentCheckingProcess();
        } else if (selectedOption === 'qpay') {
            handleQPayPayment();
        }
    };

    const handleCloseQRCodeDialog = () => {
        setShowQRCodeDialog(false);
        setShowQRButton(true); // Set showQRButton to true when the QR code dialog is closed
    };

    const handleShowQRDialog = () => {
        setShowQRCodeDialog(true);
        setShowQRButton(false); // Reset showQRButton to false when the QR code dialog is shown
    };

    const toggleDomain = (domain) => {
        if (expandedDomains.includes(domain)) {
            setExpandedDomains(expandedDomains.filter((d) => d !== domain));
        } else {
            setExpandedDomains([...expandedDomains, domain]);
        }
    };

    return (
        <DashboardLayout selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}>
            <div className="search-page-content-container">
                {searching && (
                    <div>
                        <h5 style={{ textAlign: 'center' }}>
                            <FormattedMessage id="step4.searching" defaultMessage="Step 4. Searching..." />
                        </h5><p></p>
                        <p style={{ fontWeight: '700px', color: 'black' }}>
                            <FormattedMessage id="step4.warning" defaultMessage="Note: Please do not close / refresh the page." />
                        </p>
                        <Lottie options={defaultOptions} height={200} width={200} />
                    </div>

                )}

                {!paymentSuccess && (
                    <>
                        {!continueClicked && (
                            <>
                                <h5>
                                    <FormattedMessage
                                        id="search.uploadImageStep1"
                                        defaultMessage="Step 1. Upload your face image."
                                    />
                                </h5>
                                <p></p>
                                <div className="image-uploader-wrapper">
                                    <ImageUploader
                                        file={file}
                                        previewUrl={previewUrl}
                                        onFileUpload={handleFileUpload}
                                        onDeleteImage={handleDeleteImage}
                                    />
                                </div>

                                <div className="checkout-button-wrapper" style={{ marginTop: "20px" }}>
                                    {!continueClicked && (
                                        <ContinueButton
                                            disabled={!previewUrl}
                                            onClick={handleContinueClick}
                                            loading={continueLoading}
                                        />
                                    )}
                                </div>
                            </>
                        )}

                        {continueClicked && !balanceValid && (
                            <SystemMessage>
                                <FormattedMessage
                                    id="search.insufficientBalance"
                                    defaultMessage="Insufficient balance. Please contact support."
                                />
                            </SystemMessage>
                        )}

                        {continueClicked && balanceValid && !paymentError && !qPayError && !isPaymentProcessing && !selectedOption && (
                            <PaymentOptionSelector
                                onContinue={handlePaymentOptionSelected}
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                                startPaymentProcess={startPaymentProcess}
                                loading={loadingProgress}
                            />
                        )}

                        {(selectedOption === 'stripe') && (
                            <div style={{ position: 'relative' }}>
                                {!showOverlay && !paymentSuccess && !paymentError && <BuyButtonComponent uid={currentUser.uid} docId={searchDocRef.id} />}
                                {showOverlay && (
                                    <div>
                                        {isPaymentProcessing && <Lottie options={paymentCheckOptions} height={200} width={200} />}
                                        <BuyButtonOverlay
                                            variant="contained"
                                            color="primary"
                                            disabled="disabled"
                                        >
                                            <FormattedMessage
                                                id="payment.waitingForPayment"
                                                defaultMessage="Waiting for payment... ({remainingTime} sec)"
                                                values={{ remainingTime }}
                                            />
                                        </BuyButtonOverlay>
                                    </div>
                                )}
                            </div>
                        )}

                        {(selectedOption === 'qpay') && (
                            <div style={{ position: 'relative' }}>
                                {qPayLoading && (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                                        <Lottie options={paymentCheckOptions} height={200} width={200} />
                                    </div>
                                )}
                                {showOverlay && (
                                    <div>
                                        {isPaymentProcessing &&
                                            <div>
                                                <Lottie options={paymentCheckOptions} height={200} width={200} />
                                            </div>
                                        }

                                        {showQRButton && (
                                            <ShowQRButton onClick={handleShowQRDialog}>
                                                <FormattedMessage
                                                    id="qrCode.showQR"
                                                    defaultMessage="Show QR"
                                                />
                                            </ShowQRButton>
                                        )}
                                        <BuyButtonOverlay
                                            variant="contained"
                                            color="primary"
                                            disabled="disabled"
                                        >
                                            <FormattedMessage
                                                id="payment.waitingForPayment"
                                                defaultMessage="Waiting for payment... ({remainingTime} sec)"
                                                values={{ remainingTime }}
                                            />
                                        </BuyButtonOverlay>
                                    </div>
                                )}
                                {showQRCodeDialog && (
                                    <Dialog open={showQRCodeDialog} onClose={handleCloseQRCodeDialog}>
                                        <DialogContent>
                                            <p style={{ color: '#3554A4', textAlign: 'center', fontWeight: 'bold' }}>
                                                <FormattedMessage
                                                    id="payment.amount"
                                                    defaultMessage="Fee: 20K MNT"
                                                />
                                            </p>
                                            <img src={QRCodeImage} alt="QR Code" style={{ maxWidth: '100%', height: 'auto' }} />
                                            {paymentStatus && <p>{paymentStatus}</p>}
                                        </DialogContent>
                                        {isMobile && (
                                            <Button onClick={() => setShowBankList(true)}>
                                                <FormattedMessage
                                                    id="payment.payWithBankApp"
                                                    defaultMessage="Pay with bank app"
                                                />
                                            </Button>
                                        )}
                                        <DialogActions>
                                            <Button onClick={handleCheckPayment} variant="contained" style={{ backgroundColor: "#3554A4" }}>
                                                {loadingProgress ? <CircularProgress size={24} /> : (
                                                    <FormattedMessage
                                                        id="payment.checkPayment"
                                                        defaultMessage="Check Payment"
                                                    />
                                                )}
                                            </Button>
                                            <Button onClick={handleCloseQRCodePage} variant="contained" style={{ backgroundColor: "#3554A4" }}>
                                                <FormattedMessage
                                                    id="payment.cancel"
                                                    defaultMessage="Cancel"
                                                />
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                )}
                                {showCancelConfirmation && (
                                    <Dialog open={showCancelConfirmation} onClose={() => setShowCancelConfirmation(false)}>
                                        <DialogContent>
                                            <p>
                                                <FormattedMessage
                                                    id="payment.cancelConfirmation"
                                                    defaultMessage="Are you sure you want to cancel the payment and face search?"
                                                />
                                            </p>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleCancelPayment} variant="contained" color="primary">
                                                <FormattedMessage
                                                    id="common.yes"
                                                    defaultMessage="Yes"
                                                />
                                            </Button>
                                            <Button onClick={() => setShowCancelConfirmation(false)} variant="contained" color="secondary">
                                                <FormattedMessage
                                                    id="common.no"
                                                    defaultMessage="No"
                                                />
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                )}
                                {isMobile && showBankList && (
                                    <BankListOverlay
                                        bankData={qPayMobileBankData}
                                        onClose={() => setShowBankList(false)}
                                    />
                                )}
                            </div>
                        )}
                        {paymentError && (
                            <>
                                <SystemMessage>
                                    <FormattedMessage
                                        id="payment.paymentTimeout"
                                        defaultMessage="Looks like you couldn't finish your payment on time. Please start a new search."
                                    />
                                </SystemMessage>
                                <Button variant="contained" color="primary" onClick={handleNewSearch}>
                                    <FormattedMessage
                                        id="search.newSearch"
                                        defaultMessage="New Search"
                                    />
                                </Button>
                            </>
                        )}
                    </>
                )}

                {systemUpdating && (
                    <SystemMessage>
                        <FormattedMessage
                            id="search.systemUpdating"
                            defaultMessage="We are currently updating our system. Please check back in a while."
                        />
                    </SystemMessage>
                )}

                {searchError && paymentSuccess && (
                    <>
                        <SystemMessage>
                            {searchError.toString()}
                        </SystemMessage>
                        <Button variant="contained" color="primary" onClick={handleNewSearch}>
                            New Search
                        </Button>
                    </>
                )}
                {paymentSuccess && searchCompleted && (
                    <>
                        <h5>
                            <FormattedMessage
                                id="search.result.title"
                                defaultMessage="Search Result"
                            />
                        </h5>
                        <div style={{ marginBottom: "20px" }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="interpretation-guide-content"
                                    id="interpretation-guide-header"
                                >
                                    <Typography variant="subtitle1" style={{ fontWeight: 600, color: "black" }}>
                                        <FormattedMessage
                                            id="search.result.guide"
                                            defaultMessage="⚠️ Interpretation Guide:"
                                        />
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="body2" style={{ color: "black" }}>
                                        <FormattedMessage
                                            id="search.result.guideText"
                                            defaultMessage="We utilize advanced AI algorithms to search for potential matches of the uploaded image across various internet sources. While we strive for accuracy, we do not guarantee that a person is catfishing. In addition, it is important to note that there are people who look very similar to each other and in that case users must check the sources in-depth to decide for themselves whether they are indeed the same people or not. All results should be interpreted as informational only:"
                                        />
                                    </Typography>
                                    <ul style={{ marginLeft: "20px", fontSize: "14px", marginBottom: 0, color: "black" }}>
                                        <li>
                                            <strong>
                                                -
                                                <span style={{ fontWeight: 700, color: "#50C878" }}>
                                                    <FormattedMessage
                                                        id="search.result.highMatch"
                                                        defaultMessage="90%-100%"
                                                    />
                                                </span>
                                            </strong>
                                            :{" "}
                                            <FormattedMessage
                                                id="search.result.highMatchText"
                                                defaultMessage="Fairly confident match."
                                            />
                                        </li>
                                        <li>
                                            <strong>
                                                -
                                                <span style={{ fontWeight: 700, color: "orange" }}>
                                                    <FormattedMessage
                                                        id="search.result.mediumMatch"
                                                        defaultMessage="60-89%"
                                                    />
                                                </span>
                                            </strong>
                                            :{" "}
                                            <FormattedMessage
                                                id="search.result.mediumMatchText"
                                                defaultMessage="Somewhat certain; double-check recommended."
                                            />
                                        </li>
                                        <li>
                                            <strong>
                                                -
                                                <span style={{ fontWeight: 700, color: "#FF5733" }}>
                                                    <FormattedMessage
                                                        id="search.result.lowMatch"
                                                        defaultMessage="0-59%"
                                                    />
                                                </span>
                                            </strong>
                                            :{" "}
                                            <FormattedMessage
                                                id="search.result.lowMatchText"
                                                defaultMessage="Less likely to be the same person; consider photo quality and other factors."
                                            />
                                        </li>
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: "20px",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate("/")}
                                    style={{
                                        color: "white",
                                        height: "fit-content",
                                        borderRadius: "24px",
                                        border: "1px solid #0077e4",
                                        textTransform: "none",
                                    }}
                                >
                                    <FormattedMessage
                                        id="search.newSearch"
                                        defaultMessage="New Search"
                                    />
                                </Button>
                            </div>
                        </div>
                        <div>
                            <h5 style={{ marginBottom: '20px', marginTop: '20px' }}>
                                <FormattedMessage
                                    id="search.resultBySources"
                                    defaultMessage="Result By Sources"
                                />
                            </h5>
                            {topDomains.map(([domain, items]) => (
                                <div key={domain}>
                                    <ListItem button onClick={() => toggleDomain(domain)}>
                                        <ListItemIcon>
                                            <img
                                                src={`https://www.google.com/s2/favicons?domain=${encodeURIComponent(domain)}`}
                                                alt="favicon"
                                                style={{ width: '24px', height: '24px' }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            style={{ color: 'black' }}
                                            primary={domain}
                                            secondary={
                                                <FormattedMessage
                                                    id="search.occurrences"
                                                    defaultMessage="{count} occurrences"
                                                    values={{ count: Array.isArray(items) ? items.length : 0 }}
                                                />
                                            }
                                        />
                                        {expandedDomains.includes(domain) ? <ExpandLessIcon style={{ color: 'black' }} /> : <ExpandMoreIcon style={{ color: 'black' }} />}
                                    </ListItem>
                                    <Collapse in={expandedDomains.includes(domain)} timeout="auto" unmountOnExit>
                                        <div style={{ display: 'flex', overflowX: 'auto' }}>
                                            {Array.isArray(items) && items.map((item, index) => (
                                                <div key={index} style={{ marginRight: '16px', flexShrink: 0, width: '200px' }}>
                                                    <div className="search-history-item-wrapper">
                                                        <div className="search-history-item-card" style={{ border: "1px solid var(--sd-color-hex-blue)" }}>
                                                            <div className="search-history-image-placeholder">
                                                                <div className="search-history-image ratio ratio-1x1 flex-center cover-image">
                                                                    <img alt="search-history-item" src={item.base64} />
                                                                </div>
                                                                <div
                                                                    className="search-history-stats"
                                                                    style={{ fontWeight: 700, color: getColorBasedOnScore(item.score) }}
                                                                >
                                                                    {item.score}%
                                                                </div>
                                                            </div>
                                                            <Button
                                                                size="large"
                                                                style={{ color: "var(--sd-color-hex-blue)", textTransform: "none", backgroundColor: "white", display: "flex", alignItems: "center" }}
                                                                component="a"
                                                                href={item.url}
                                                                target="_blank"
                                                            >
                                                                <FormattedMessage id="search.browse" defaultMessage="Browse" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Collapse>
                                </div>
                            ))}

                            <h5 style={{ marginBottom: '20px', marginTop: '20px' }}>
                                <FormattedMessage
                                    id="search.resultByImages"
                                    defaultMessage="Result By Images"
                                />
                            </h5>
                            <Grid container spacing={2}>
                                {sortedImages.map((item, index) => (
                                    <Grid item xs={6} sm={6} md={4} lg={3} xl={2} gap={20} key={index}>
                                        <div className="search-history-item-wrapper">
                                            <div className="search-history-item-card" style={{ border: "1px solid var(--sd-color-hex-blue)" }}>
                                                <div className="search-history-image-placeholder">
                                                    <div className="search-history-image ratio ratio-1x1 flex-center cover-image">
                                                        <img alt="search-history-item-02" src={item.base64} />
                                                    </div>
                                                    <div
                                                        className="search-history-stats"
                                                        style={{ fontWeight: 700, color: getColorBasedOnScore(item.score) }}
                                                    >
                                                        {item.score}%
                                                    </div>
                                                </div>
                                                <Button
                                                    size="large"
                                                    style={{ color: "var(--sd-color-hex-blue)", textTransform: "none", backgroundColor: "white", display: "flex", alignItems: "center" }}
                                                    component="a"
                                                    href={item.url}
                                                    target="_blank"
                                                >
                                                    <img
                                                        src={`https://www.google.com/s2/favicons?domain=${encodeURIComponent(item.url)}`}
                                                        alt="favicon"
                                                        style={{ width: "16px", height: "16px", marginRight: "8px" }}
                                                    />
                                                    <FormattedMessage id="search.browse" defaultMessage="Browse" />
                                                </Button>
                                            </div>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </>
                )}
            </div>
        </DashboardLayout >
    )
};

export default Search;