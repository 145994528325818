import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { useAuth } from "../AuthContext.js";
import { useNavigate, useLocation, Form } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Carousel } from 'react-bootstrap';
import { grey } from "@mui/material/colors";
import { FormattedMessage, useIntl } from 'react-intl';


const LandingPage = ({ selectedLanguage, setSelectedLanguage }) => {
    const { currentUser, handleSignInOrRegister } = useAuth(); // Destructure the handleSignInOrRegister function from useAuth
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [activeBlog, setActiveBlog] = useState({});
    const intl = useIntl();

    const openModal = (blog) => {
        setActiveBlog(blog);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const slides = [
        { img: intl.formatMessage({ id: 'hero1.image' }), alt: intl.formatMessage({ id: 'hero1.alt' }) },
        { img: intl.formatMessage({ id: 'hero2.image' }), alt: intl.formatMessage({ id: 'hero2.alt' }) },
    ];


    useEffect(() => {
        if (currentUser) {
            navigate("/search");
        }
    }, [pathname, currentUser]);

    useEffect(() => {
        const next = (currentSlide + 1) % slides.length;
        const id = setTimeout(() => setCurrentSlide(next), 3000);  // Change image every 3 seconds
        return () => clearTimeout(id);
    }, [currentSlide]);

    const handleSearchButtonClick = () => {
        // Call handleSignInOrRegister when the button is clicked
        handleSignInOrRegister();
    };

    const blogs = [
        {
            "title": intl.formatMessage({ id: 'blog1.title' }),
            "snippet": intl.formatMessage({ id: 'blog1.snippet' }),
            "body": intl.formatMessage({ id: 'blog1.body' }),
            "image": "/images/blog0.webp",
            "readMoreLink": "#"
        },
        {
            "title": intl.formatMessage({ id: 'blog2.title' }),
            "snippet": intl.formatMessage({ id: 'blog2.snippet' }),
            "body": intl.formatMessage({ id: 'blog2.body' }),
            "image": "/images/blog1.webp",
            "readMoreLink": "#"
        }
    ];

    const accordionData = [
        {
            "sdAccordionID": "accordion-1",
            "sdAccordionQuestion": intl.formatMessage({ id: 'faq1.question' }),
            "sdAccordionAnswer": intl.formatMessage({ id: 'faq1.answer' }),
        },
        {
            "sdAccordionID": "accordion-2",
            "sdAccordionQuestion": intl.formatMessage({ id: 'faq2.question' }),
            "sdAccordionAnswer": intl.formatMessage({ id: 'faq2.answer' }),
        },
        {
            "sdAccordionID": "accordion-3",
            "sdAccordionQuestion": intl.formatMessage({ id: 'faq3.question' }),
            "sdAccordionAnswer": intl.formatMessage({ id: 'faq3.answer' }),
        },
        {
            "sdAccordionID": "accordion-4",
            "sdAccordionQuestion": intl.formatMessage({ id: 'faq4.question' }),
            "sdAccordionAnswer": intl.formatMessage({ id: 'faq4.answer' }),
        },
        {
            "sdAccordionID": "accordion-5",
            "sdAccordionQuestion": intl.formatMessage({ id: 'faq5.question' }),
            "sdAccordionAnswer": intl.formatMessage({ id: 'faq5.answer' }),
        },
        {
            "sdAccordionID": "accordion-6",
            "sdAccordionQuestion": intl.formatMessage({ id: 'faq6.question' }),
            "sdAccordionAnswer": intl.formatMessage({ id: 'faq6.answer' }),
        },
        {
            "sdAccordionID": "accordion-7",
            "sdAccordionQuestion": intl.formatMessage({ id: 'faq7.question' }),
            "sdAccordionAnswer": intl.formatMessage({ id: 'faq7.answer' }),
        },
        {
            "sdAccordionID": "accordion-8",
            "sdAccordionQuestion": intl.formatMessage({ id: 'faq8.question' }),
            "sdAccordionAnswer": intl.formatMessage({ id: 'faq8.answer' }),
        }
    ];

    return (
        <>
            <Header selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
            <main>
                {/* _BEGIN >> SECTION - HERO << WOS */}
                <section id="home" className="sd-section-hero">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="position-relative">
                                    <div className="sd-hero-content-wrapper d-flex flex-column justify-content-center position-relative">
                                        <h1 className="sd-section-hero-title">
                                            <FormattedMessage id="hero.title" />
                                        </h1>
                                        <p className="sd-section-hero-text">
                                            <FormattedMessage id="hero.text" values={{ span: (chunks) => <span style={{ fontWeight: "700", color: "var(--sd-color-pink)" }}>{chunks}</span> }} />
                                        </p>
                                        <div>
                                            <button className="primary-btn" style={{ backgroundColor: "var(--sd-color-pink)" }} onClick={() => navigate('/try-search')}>
                                                <FormattedMessage id="hero.searchButton" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 d-none d-md-block">
                                <div className="sd-hero-cover-placeholder position-relative">
                                    <div className="flex-center contain-image ratio ratio-1x1">
                                        <img src={slides[currentSlide].img} alt={slides[currentSlide].alt} className="w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 d-block d-md-none mt-4">
                                <div className="sd-hero-cover-placeholder position-relative">
                                    <div className="flex-center contain-image ratio ratio-1x1">
                                        <img src={slides[currentSlide].img} alt={slides[currentSlide].alt} className="w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* _ENDS >> SECTION - HERO << WOS */}

                <section id="see-it-in-action" className="sd-section-action">
                    <div className="container">
                        <div className="sd-compatible-content-wrapper d-flex flex-column text-center">
                            <h2 className="sd-compatible-title">
                                <FormattedMessage id="watch.app.works" defaultMessage="See It In Action in 60 sec" />
                            </h2>
                            <div className="sd-video-container">
                                <video controls width="100%" style={{ maxWidth: "800px", height: "auto", maxHeight: "800px" }}>
                                    <source src="https://firebasestorage.googleapis.com/v0/b/safedate-ai.appspot.com/o/safedate-ai-demo.mp4?alt=media&token=35addbda-9fad-4cbb-ba08-b4b2cf9e7a96" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                </section>
                {/* _BEGIN >> SECTION - COMPATIBLE << WOS */}
                <section id="compatible" className="sd-section-compatible">
                    <div className="container">
                        <div className="sd-compatible-content-wrapper d-flex flex-column text-center">
                            <h2 className="sd-compatible-title">
                                <FormattedMessage id="platforms.compatible" defaultMessage="Platforms We Are Compatible With" />
                            </h2>

                            <div className="sd-compatible-platforms-list d-flex align-items-center justify-content-center flex-wrap">
                                {/* COMPATIBLE PLATFORM 01 */}
                                <div className="sd-compatible-platforms-item ratio ratio-1x1 flex-center cover-image flex-auto-0">
                                    <img
                                        src="https://framerusercontent.com/images/CxktOjAVPUGyzE0oAA45XZNszTs.png?scale-down-to=512"
                                        alt="compatible-platform-01"
                                    />
                                </div>
                                {/* COMPATIBLE PLATFORM 02 */}
                                <div className="sd-compatible-platforms-item ratio ratio-1x1 flex-center cover-image flex-auto-0">
                                    <img
                                        src="https://framerusercontent.com/images/LxM2P3TakRibp5jalNQ5iTeTYBQ.png"
                                        alt="compatible-platform-02"
                                    />
                                </div>
                                {/* COMPATIBLE PLATFORM 03 */}
                                <div className="sd-compatible-platforms-item ratio ratio-1x1 flex-center cover-image flex-auto-0">
                                    <img
                                        src="https://framerusercontent.com/images/NDLku5ULhqaJQYpkub0zfbXabo.png"
                                        alt="compatible-platform-03"
                                    />
                                </div>
                                {/* COMPATIBLE PLATFORM 04 */}
                                <div className="sd-compatible-platforms-item ratio ratio-1x1 flex-center cover-image flex-auto-0">
                                    <img
                                        src="https://framerusercontent.com/images/x0DjGbhy142n4x4kGIADJhRDsvs.png?scale-down-to=512"
                                        alt="compatible-platform-04"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* _ENDS >> SECTION - COMPATIBLE << WOS */}

                {/* _BEGIN >> SECTION - ABOUT SECTION << WOS */}
                <section id="about" className="sd-section-about text-center">
                    <div className="container">
                        <div className="sd-about-content-wrapper d-flex flex-column">
                            <h2 className="sd-about-title">
                                <FormattedMessage id="about.title" />
                            </h2>
                            <p></p>
                            <h5 className="sd-about-sub-title">
                                <FormattedMessage id="about.description" />
                            </h5>
                            <p></p>
                        </div>
                    </div>
                </section>
                {/* _ENDS >> SECTION - ABOUT SECTION << WOS */}

                {/* _BEGIN >> SECTION - HOW IT WORKS SECTION << WOS */}
                <section id="how" className="sd-section-about text-center">
                    <div className="container">
                        <div className="sd-about-content-wrapper d-flex flex-column">
                            <h2 className="sd-about-title">
                                <FormattedMessage id="howItWorks.title" />
                            </h2>
                            <p></p>
                            <p></p>
                        </div>
                    </div>
                </section>
                {/* _ENDS >> SECTION - ABOUT SECTION << WOS */}

                {/* _BEGIN >> SECTION - FEATURE SECTION << WOS */}
                <section id="feature" className="sd-section-feature">
                    <div className="container">
                        <div className="sd-feature-column d-flex flex-column">
                            {/* FEATURE 01 */}
                            <div className="sd-feature-row">
                                <div className="sd-feature-content d-flex flex-column">
                                    <h4 className="sd-feature-content-title">
                                        <FormattedMessage id="feature1.title" values={{ span: (chunks) => <span style={{ color: "var(--sd-color-pink)" }}>{chunks}</span> }} />
                                    </h4>
                                    <h6 className="sd-feature-content-text">
                                        <FormattedMessage id="feature1.text" />
                                    </h6>
                                </div>
                                <div className="sd-feature-cover ratio ratio-1x1 flex-center flex-auto-0 contain-image">
                                    <img
                                        alt="feature-01"
                                        src="/images/feature1_n.webp"
                                    />
                                </div>
                            </div>

                            {/* FEATURE 02 */}
                            <div className="sd-feature-row-reverse">
                                <div className="sd-feature-content d-flex flex-column">
                                    <h4 className="sd-feature-content-title">
                                        <FormattedMessage id="feature2.title" values={{ span: (chunks) => <span style={{ color: "var(--sd-color-pink)" }}>{chunks}</span> }} />
                                    </h4>
                                    <h6 className="sd-feature-content-text">
                                        <FormattedMessage id="feature2.text" />
                                    </h6>
                                </div>
                                <div className="sd-feature-cover ratio ratio-1x1 flex-center flex-auto-0 contain-image">
                                    <img
                                        alt="feature-02"
                                        src="/images/feature2_n.webp"
                                    />
                                </div>
                            </div>

                            {/* FEATURE 03 */}
                            <div className="sd-feature-row">
                                <div className="sd-feature-content d-flex flex-column">
                                    <h4 className="sd-feature-content-title">
                                        <FormattedMessage id="feature3.title" values={{ span: (chunks) => <span style={{ color: "var(--sd-color-pink)" }}>{chunks}</span> }} />
                                    </h4>
                                    <h6 className="sd-feature-content-text">
                                        <FormattedMessage id="feature3.text" />
                                    </h6>
                                </div>
                                <div className="sd-feature-cover ratio ratio-1x1 flex-center flex-auto-0 contain-image">
                                    <img
                                        alt="feature-03"
                                        src="/images/feature3_n.webp"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* _ENDS >> SECTION - FEATURE SECTION << WOS */}

                {/* _BEGIN >> SECTION - FAQ SECTION << WOS */}
                <section id="faq" className="sd-section-faq">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 d-md-flex align-items-center justify-content-center">
                                <h2 className="text-center mb-4">
                                    <FormattedMessage id="faq.title" />
                                </h2>
                            </div>
                            <div className="col-md-6">
                                <div className="sd-faq-accordion-container d-flex flex-column align-items-center justify-content-center flex-wrap">
                                    {accordionData.map((item, index) => {
                                        return (
                                            <Accordion key={index} className="sd-faq-accordion">
                                                <AccordionSummary
                                                    id={item.sdAccordionID}
                                                    className="sd-faq-accordion-header"
                                                    aria-controls="panel1-content"
                                                    expandIcon={
                                                        <div className="sd-faq-accordion-icon">
                                                            <span></span>
                                                            <span></span>
                                                        </div>
                                                    }
                                                >
                                                    {item.sdAccordionQuestion}
                                                </AccordionSummary>
                                                <AccordionDetails className="sd-faq-accordion-content">
                                                    {item.sdAccordionAnswer}
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="blogs">
                    <h2 className="text-center mb-4">
                        <FormattedMessage id="blogs.title" />
                    </h2>
                    <div className="sd-section-blogs d-flex justify-content-center align-items-center">
                        <Carousel>
                            {blogs.map((blog, index) => (
                                <Carousel.Item key={index} className="sd-blog-item">
                                    <img
                                        className="sd-blog-img img-fluid"
                                        src={blog.image}
                                        alt={"Slide " + (index + 1)}
                                    />
                                    <Carousel.Caption className="sd-blog-content">
                                        <h3>{blog.title}</h3>
                                        <p>{blog.snippet} <a onClick={() => openModal(blog)} className="read-more-link"><FormattedMessage id="blogs.readMore" /></a></p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                    {showModal && (
                        <div className="blog-modal-overlay" onClick={closeModal}>
                            <div className="blog-modal-content" onClick={e => e.stopPropagation()}>
                                <button className="close-modal-btn" onClick={closeModal}>&times;</button>
                                <h2>{activeBlog.title}</h2>
                                <img src={activeBlog.image} alt="Blog Image" className="modal-blog-img" />
                                <div dangerouslySetInnerHTML={{ __html: activeBlog.body }}></div>
                            </div>
                        </div>
                    )}
                </section>

                {/* _BEGIN >> SECTION - MOTTO SECTION << WOS */}
                <section id="motto" className="sd-section-motto">
                    <div className="container">
                        <div className="sd-motto-container">
                            <div className="sd-motto-content d-flex flex-column">
                                <h2 className="sd-motto-content-title">
                                    <FormattedMessage id="motto.title" />
                                </h2>
                                <p className="sd-motto-content-text">
                                    <FormattedMessage id="motto.text" />
                                </p>
                            </div>
                            <div style={{ width: "50%", display: "flex", justifyContent: "center" }}>
                                <img
                                    src="/images/motto.png"
                                    alt="motto-cover"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                {/* _BEGIN >> SECTION - TRY NOW SECTION << WOS */}
                <section id="about" className="sd-section-about text-center">
                    <div className="container">
                        <div className="sd-about-content-wrapper d-flex flex-column">
                            <h2 className="sd-about-title">
                                <FormattedMessage id="cta.title" />
                            </h2>
                            <p></p>
                            <p style={{ color: "black" }}>
                                <FormattedMessage id="cta.text" values={{ email: "hello@safedate.ai" }} />
                            </p>
                            <div>
                                <button className="primary-btn" style={{ backgroundColor: "var(--sd-color-pink)" }} onClick={() => navigate('/try-search')}>
                                    <FormattedMessage id="cta.searchButton" />
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                {/* _ENDS >> SECTION - MOTTO SECTION << WOS */}
            </main>

            {/* _BEGIN >> FOOTER << WOS */}
            <footer>
                <div className="container text-center" style={{ color: "black" }}>
                    <FormattedMessage id="footer.text" values={{ year: 2024, email: "hello@safedate.ai" }} />
                </div>
            </footer>
            {/* _ENDS >> FOOTER << WOS */}
        </>
    );
};

export default LandingPage;
